import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { SRLWrapper } from 'simple-react-lightbox'
import { Layout, SEO } from '@global'
import { Testimonials, SocialProof } from '@layouts'
import { useInc } from '@hooks'
import { testimonials as testimonialsData } from '../../data/testimonials'

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityGallery(sort: { fields: _createdAt, order: ASC }) {
        edges {
          node {
            images {
              _key
              alt
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const galleryImages = data.allSanityGallery.edges[0].node.images
  const [cardsToShow, { inc, reset }] = useInc({
    minValue: 9,
    initial: 9,
    step: galleryImages.length - 9,
  })

  const testimonials = testimonialsData.slice(8, 11)

  return (
    <div className="relative">
      <SEO title="Gallery" />
      <Layout>
        <section className="relative py-20">
          <div className="container">
            <div className="text-center">
              <h2 className="font-serif text-5xl font-bold text-blue-400 capitalize lg:text-6xl">
                Gallery
              </h2>
              <p className="max-w-md mx-auto mt-1 text-base text-lg leading-snug text-gray-400 lg:max-w-lg">
                We take pride in bringing our customers’ visions to life through
                our bathroom transformations.
              </p>
            </div>
            <div className="mt-8 md:mt-10 lg:mt-16">
              <SRLWrapper>
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {galleryImages.slice(0, cardsToShow).map(image => (
                    <div className="overflow-hidden rounded shadow cursor-pointer">
                      <Img
                        fluid={image.asset.fluid}
                        alt={image.alt}
                        key={image._key}
                        className="w-full h-full transition duration-300 transform scale-110 hover:scale-100"
                      />
                    </div>
                  ))}
                </div>
              </SRLWrapper>
            </div>
          </div>
          <div className="flex items-center justify-center pt-10 pb-5 lg:pt-20">
            {cardsToShow < galleryImages.length ? (
              <button onClick={inc} className="text-lg btn sm:text-xl">
                Show More
              </button>
            ) : (
              <button
                onClick={() => {
                  reset()
                  window.scrollTo(0, 0)
                }}
                className="text-lg btn sm:text-xl"
              >
                Back to top
              </button>
            )}
          </div>
        </section>
        <Testimonials data={testimonials} />
        <SocialProof />
      </Layout>
    </div>
  )
}
export default GalleryPage
